:root {
  background-color: #fff;
  color: #fff;
  font-family: 'Muller', sans-serif;
  font-weight: 400;
  line-height: normal;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
body {
  padding: 0;
  margin: 0;
}

html {
  overflow: hidden;
  height: 100%;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

button {
  -webkit-appearance: none;
  border-radius: 0;
  text-align: inherit;
  background: none;
  box-shadow: none;
  padding: 0;
  cursor: pointer;
  border: none;
  color: inherit;
  font: inherit;
}

a {
  color: unset;
  text-decoration: none;
}

img {
  display: block;
}

svg {
  pointer-events: none;
}

img {
  width: 100%;
}

hr {
  border-right: none;
  border-bottom: none;
  border-left: none;
  margin: 15px 0;
  opacity: 0.5;
}

@mixin wrapper-styles($bg-url) {
  position: relative;
  display: flex;
  overflow: hidden;
  height: 100%;
  max-height: 960px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  aspect-ratio: 9 / 16;
  background: url(#{$bg-url});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
