@import '../../../../assets/styles/index.scss';

.wrapper {
  @include wrapper-styles('../../../../assets/img/bg12.jpg');

  align-items: flex-start;
}

.lorenzo {
  position: absolute;
  bottom: 0;
  left: 135px;
  max-width: 415.5px;
  max-height: 890px;
  background-size: cover;
}

.title {
  max-width: 353.5px;
  max-height: 129.5px;
  margin-bottom: 240px;
}

@media (max-width: 470px) {
  .wrapper {
    justify-content: unset;
  }

  .title {
    margin-top: -10px;
  }

  .lorenzo {
    scale: 0.7;
    transform-origin: bottom left;
  }
}
