@import '../../assets/styles/index.scss';

.wrapper {
  @include wrapper-styles('../../assets/img/bg1.jpg');

  justify-content: space-evenly;
}

.startPageTitle {
  width: 100%;
  align-self: flex-start;
  margin-top: 11vh;
  color: #fff;
  font-family: 'Muller', sans-serif;
  font-size: 65px;
  font-style: italic;
  font-weight: 800;
  line-height: 75px;
  text-transform: uppercase;
  transform: rotate(-10deg);

  &::before {
    position: absolute;
    left: 0;
    width: 570px;
    height: 70px;
    animation: animation_to_fix_backdrop_filter 0.3s both;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    content: '';
    transform: skew(10deg);
  }

  &::after {
    position: absolute;
    top: 152px;
    left: 190px;
    width: 360px;
    height: 70px;
    animation: animation_to_fix_backdrop_filter 0.3s both;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    content: '';
    transform: skew(10deg);
  }
}

.title1,
.title3 {
  position: relative;
}

.title1 {
  margin-left: -40px;
}

.title3 {
  z-index: 2;
  margin-left: 50px;
}

.button {
  border: 20px solid #000;
}

@keyframes animation_to_fix_backdrop_filter {
  from {
    transform: translate3d(0, 0.5px, 0);
  }
}
