@import '../../../../assets/styles/index.scss';

.wrapper {
  @include wrapper-styles('../../../../assets/img/bg19.jpg');

  justify-content: unset;
}

.checkBoxWrapper {
  display: flex;
  height: 39%;
  align-items: center;
  padding: 50px;
  border-radius: 50px;
  margin: 20px;
  animation: animation_to_fix_backdrop_filter 0.3s both;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  text-align: left;

  &.correct {
    border: 3px solid rgba(0, 128, 0, 0.5);
  }

  &.incorrect {
    border: 3px solid rgba(255, 0, 0, 0.5);
  }
}

.container {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 24px;
  font-weight: 500;
}

.text {
  font-weight: 300;
}

.img1,
.img2 {
  width: 100%;
  height: 100%;
  align-self: flex-start;
}

.img1 {
  margin-right: 55px;
  background: url('../../../../assets/img/pack1.png') no-repeat;
  background-size: contain;
}

.img2 {
  background: url('../../../../assets/img/pack2.png') no-repeat;
  background-size: contain;
}

.button {
  position: relative;
  width: 170px;
  height: 72px;
  justify-content: center;
  border-radius: 20px;
  margin-top: 40px;
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  white-space: nowrap;

  &::before {
    position: absolute;
    bottom: -10px;
    top: -10px;
    right: -10px;
    left: -10px;
    z-index: -1;
    border-radius: 30px;
    background: rgba(0, 0, 0, 0.5);
    content: '';
  }

  &.correct {
    background: #fff;

    &::after {
      position: absolute;
      bottom: -10px;
      top: -10px;
      right: -10px;
      left: -10px;
      border-radius: 30px;
      background: url('../../../../assets/img/correctBtn.png') no-repeat;
      background-size: cover;
      content: '';
    }
  }

  &.incorrect {
    background: #fff;

    &::after {
      position: absolute;
      top: -10px;
      right: -10px;
      bottom: -10px;
      left: -10px;
      border-radius: 30px;
      background: url('../../../../assets/img/incorrectBtn.png') no-repeat;
      background-size: cover;
      content: '';
    }
  }
}

@media (max-width: 470px) {
  .container {
    margin: 0 10px 10px 0;
  }

  .button {
    width: 150px;
    height: 62px;
    margin-top: 30px;
    font-size: 16px;
    line-height: 15px;

    &::before {
      padding: 35px 70px;
      border-radius: 20px;
    }
  }

  .checkBoxWrapper {
    padding: 20px;
  }

  .title {
    font-size: 16px;
  }

  .img1,
  .img2 {
    margin-right: 15px;
  }
}

@keyframes animation_to_fix_backdrop_filter {
  from {
    transform: translate3d(0, 0.5px, 0);
  }
}
