.wrapper {
  display: flex;
  height: fit-content;
  flex-direction: column;
  align-items: center;
  align-self: flex-end;
  justify-content: center;
  padding-bottom: 80px !important;
  border-radius: 50px 50px 0 0;
  animation: animation_to_fix_backdrop_filter 0.3s both;
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
}

.text,
.text2 {
  padding: 60px 60px 20px;
  color: #fff;
  font-family: 'Muller', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 29px;
  text-align: left;
}

.text2 {
  display: none;
}

@media (max-width: 470px) {
  .text,
  .text2 {
    padding: 30px 30px 20px;
    font-size: 16px;
    line-height: 18px;
  }
}

@keyframes animation_to_fix_backdrop_filter {
  from {
    transform: translate3d(0, 0.5px, 0);
  }
}
