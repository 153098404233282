@import '../../../../assets/styles/index.scss';

.wrapper {
  @include wrapper-styles('../../../../assets/img/bg17.jpg');

  justify-content: space-between;

  &.blocked {
    pointer-events: none;
  }
}

.wrapperForOpenPopup {
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
}

.buttonContainer {
  position: relative;
  height: 100%;

  &.hidden {
    display: none;
  }
}

.buttonWrapper1,
.buttonWrapper2,
.buttonWrapper3,
.buttonWrapper4 {
  position: absolute;
  border: 9px solid rgba(0, 0, 0, 0.2);
  border-radius: 20px;

  &.correct {
    border: 9px solid rgba(0, 128, 0, 0.5);
  }
}

.buttonWrapper1 {
  top: 330px;
  right: 110px;
}

.buttonWrapper2 {
  top: 490px;
  right: 145px;
}

.buttonWrapper3 {
  top: 450px;
  left: 190px;
}

.buttonWrapper4 {
  top: 660px;
  left: 140px;
}

.button1,
.button2,
.button3,
.button4 {
  padding: 20px;
  border-radius: 10px;
}

.messageWrapper {
  flex-direction: row-reverse;
  align-self: unset;
  padding: 30px 0 20px;
}

.text {
  padding: 0 60px 0 40px;
}

.img {
  width: 42px;
  height: 63px;
  margin-left: 55px;
  background: url('../../../../assets/img/help_hand.png') no-repeat;
  background-size: contain;
}

.popupImg1 {
  background: url('../../../../assets/img/popupImg1.png') no-repeat;
  background-size: contain;
}

.popupImg2 {
  background: url('../../../../assets/img/popupImg2.png') no-repeat;
  background-size: contain;
}

.popupImg3 {
  background: url('../../../../assets/img/popupImg3.png') no-repeat;
  background-size: contain;
}

.popupImg4 {
  background: url('../../../../assets/img/popupImg4.png') no-repeat;
  background-size: contain;
}

.progressBar {
  bottom: 2%;
}

.button {
  &.correct {
    background: #fff;

    &::after {
      position: absolute;
      top: -9px;
      left: -12px;
      width: 200.5px;
      height: 96.5px;
      background: url('../../../../assets/img/correctBtn.png') no-repeat;
      background-size: contain;
      content: '';
    }
  }

  &.incorrect {
    background: #fff;

    &::after {
      position: absolute;
      top: -9px;
      left: -12px;
      width: 200.5px;
      height: 96.5px;
      background: url('../../../../assets/img/incorrectBtn.png') no-repeat;
      background-size: contain;
      content: '';
    }
  }
}

@media (max-width: 470px) {
  .buttonWrapper1 {
    top: 47%;
    right: 50px;
  }

  .buttonWrapper2 {
    top: 60%;
    right: 115px;
  }

  .buttonWrapper3 {
    top: 61%;
    left: 110px;
  }

  .buttonWrapper4 {
    top: 82%;
    left: 70px;
  }

  .button {
    background: #fff;

    &.correct,
    &.incorrect {
      &::after {
        top: -9px;
        left: -8px;
        width: 134.5px;
        height: 80.5px;
      }
    }
  }
}
