.popupWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 53px 58px;
  border-radius: 50px;
  margin: 20px;
  animation: animation_to_fix_backdrop_filter 0.3s both;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  text-align: left;
}

.img {
  width: 382px;
  height: 369.5px;
  margin-bottom: 48px;
}

.caption {
  margin-bottom: 37px;
  font-size: 15px;
  font-style: italic;
  font-weight: 300;
}

.text {
  margin-bottom: 39px;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
}

.buttonContainer {
  display: flex;
  gap: 35px;
}

.button {
  position: relative;
  width: 170px;
  height: 72px;
  justify-content: center;
  border-radius: 20px;
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  white-space: nowrap;

  &::before {
    position: absolute;
    z-index: -1;
    top: -11.5px;
    left: -12px;
    padding: 47px 97px;
    border-radius: 30px;
    background: rgba(0, 0, 0, 0.5);
    content: '';
  }
}

@media (max-width: 470px) {
  .popupWrapper {
    width: 100%;
    padding: 25px 20px;
    margin: 10px 20px;
  }

  .img {
    width: 100%;
    height: 100%;
    padding-top: 100%;
    margin-bottom: 0;
  }

  .caption {
    margin-bottom: 10px;
    font-size: 12px;
  }

  .text {
    margin-bottom: 25px;
    font-size: 16px;
    line-height: 15px;
  }

  .button {
    width: 120px;
    height: 47px;
    border-radius: 15px;
    font-size: 16px;
    line-height: 15px;

    &::before {
      top: -11px;
      left: -10px;
      padding: 35px 70px;
      border-radius: 20px;
    }
  }
}

@keyframes animation_to_fix_backdrop_filter {
  from {
    transform: translate3d(0, 0.5px, 0);
  }
}
