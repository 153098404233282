@import '../../../../assets/styles/index.scss';

.wrapper {
  @include wrapper-styles('../../../../assets/img/bg13.jpg');

  justify-content: flex-end;
}

.messageWrapper {
  align-self: unset;
}

.text {
  padding: 60px 50px 30px;
  font-size: 23px;
}

.buttonWrapper {
  margin-bottom: 20px;
  font-size: 27px;
  line-height: 30px;
}

.arrow {
  display: block;
}

@media (max-width: 470px) {
  .buttonWrapper {
    font-size: 16px;
    line-height: 15px;
  }

  .text {
    padding: 30px;
    font-size: 16px;
  }
}
