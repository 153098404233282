@import '../../../../assets/styles/index.scss';

.wrapper {
  @include wrapper-styles('../../../../assets/img/bg6.jpg');

  justify-content: flex-end;
}

.messageWrapper {
  align-self: unset;
}

.text {
  padding: 30px 60px 0;
  font-size: 23px;
}

.text2 {
  display: block;
  padding: 0 60px 30px;
  font-size: 23px;
}

.img {
  width: 356px;
  height: 130px;
  background: url('../../../../assets/img/crystals.png') no-repeat;
  background-size: contain;
}

.buttonWrapper {
  margin-bottom: 20px;
  font-size: 27px;
  line-height: 30px;
}

.arrow {
  display: block;
}

@media (max-width: 470px) {
  .buttonWrapper {
    font-size: 16px;
    line-height: 15px;
  }

  .text,
  .text2 {
    padding: 30px 30px 0;
    font-size: 16px;
    line-height: 18px;
  }

  .text2 {
    padding: 0 30px 20px;
  }

  .img {
    width: 178px;
    height: 75px;
  }
}
