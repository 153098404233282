@import '../../../../assets/styles/index.scss';

.wrapper {
  @include wrapper-styles('../../../../assets/img/bg5.jpg');

  justify-content: flex-end;

  &.blocked {
    pointer-events: none;
  }
}

.crystalsWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.blueCrystal {
  position: absolute;
  top: 110px;
  left: 170px;
  max-width: 182px;
  max-height: 166px;
  background-size: contain;
}

.purpleCrystal {
  position: absolute;
  top: 170px;
  right: 0;
  max-width: 158px;
  max-height: 173.5px;
  background-size: contain;
}

.redCrystal {
  position: absolute;
  top: 360px;
  left: 0;
  max-width: 210.5px;
  max-height: 189px;
  background-size: contain;
}

.greenCrystal {
  position: absolute;
  top: 280px;
  left: 240px;
  max-width: 200.5px;
  max-height: 191px;
  background-size: contain;
}

.brownCrystal {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 178px;
  max-height: 165.5px;
  background-size: contain;
}

.yellowCrystal {
  position: absolute;
  top: 150px;
  left: 10px;
  max-width: 170px;
  max-height: 148.5px;
}

.messageWrapper {
  min-width: 495px;
  flex-direction: row-reverse;
  align-items: flex-start;
  align-self: unset;
  padding: 30px 0 20px;
  white-space: nowrap;
}

.text {
  padding: 0 60px 30px 40px;
}

.img {
  width: 42px;
  height: 63px;
  margin-left: 55px;
  background: url('../../../../assets/img/help_hand.png') no-repeat;
  background-size: contain;
}

.shake {
  animation: shake 0.5s;
  filter: drop-shadow(22px 14px 18px rgb(255, 0, 0));
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.flyAway {
  animation: flyAway 1s forwards;
}

@keyframes flyAway {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }

  100% {
    transform: translate(100vw, -100vh) rotate(360deg);
  }
}

@media (max-width: 470px) {
  .crystalsWrapper {
    scale: 0.9;
    transform-origin: top;
  }

  .yellowCrystal {
    top: 20px;
  }

  .greenCrystal {
    top: 150px;
    left: 10px;
  }

  .purpleCrystal {
    top: 280px;
  }

  .messageWrapper {
    min-width: unset;
    align-items: center;
    padding: 20px 0;
  }

  .text {
    padding: 0 60px 0 40px;
  }

  .img {
    scale: 0.8;
  }
}
