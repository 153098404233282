@import '../../assets/styles/index.scss';

.wrapper {
  @include wrapper-styles('../../assets/img/bg21.jpg');
}

.container {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 40px;
}

.title {
  width: 100%;
  height: 158px;
  background: url('../../assets/img/title11.png') no-repeat;
  background-size: contain;
}

.text {
  font-size: 30px;
  font-weight: 300;
}

@media (max-width: 470px) {
  .title {
    width: 100%;
  }

  .text {
    font-size: 18px;
  }
}
