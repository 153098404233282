@import '../../../assets/styles/index.scss';

.wrapper {
  @include wrapper-styles('../../../assets/img/bg14.jpg');

  align-items: flex-end;
}

.title {
  max-width: 359px;
}

.buttonWrapper {
  align-self: baseline;
  padding: 10px;
  margin-bottom: 20px;
  margin-left: 300px;
  background: rgba(0, 0, 0, 0.5);
}

.button {
  position: relative;
  padding: 28px 22px;
  font-family: 'Loreal';
  font-size: 48px;
  font-weight: 800;
}

@media (max-width: 470px) {
  .buttonWrapper {
    align-self: center;
    margin-left: unset;
  }
}
