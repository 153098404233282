@import '../../../../assets/styles/index.scss';

.wrapper {
  @include wrapper-styles('../../../../assets/img/bg19.jpg');

  align-items: flex-start;
  justify-content: flex-start;
}

.robot {
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 503px;
  max-height: 886.5px;
  background-size: cover;
}

.title {
  max-width: 335.5px;
  max-height: 176px;
  align-self: flex-start;
  margin-top: 160px;
}

@media (max-width: 470px) {
  .title {
    margin-top: unset;
  }

  .robot {
    scale: 0.8;
    transform-origin: bottom right;
  }
}
