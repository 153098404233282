.buttonWrapper {
  display: inline-block;
  border-radius: 60px;
}

.button {
  display: flex;
  width: fit-content;
  align-items: center;
  padding: 20px 40px;
  border-radius: 50px;
  background: linear-gradient(#f86e66, #fb568e);
  color: #fff;
  font-family: 'Muller', sans-serif;
  font-size: 14px;
  font-weight: 400;
  gap: 25px;
  line-height: 18px;
}

.arrow {
  display: none;
  width: 28.5px;
  height: 20.5px;
}
