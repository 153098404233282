@import '../../../../assets/styles/index.scss';

.wrapper {
  @include wrapper-styles('../../../../assets/img/bg4.jpg');

  flex-direction: row;
}

.img {
  display: none;
}

.buttonWrapper {
  margin-bottom: 20px;
  font-size: 27px;
  line-height: 30px;
}

.arrow {
  display: block;
}

@media (max-width: 470px) {
  .buttonWrapper {
    font-size: 16px;
    line-height: 15px;
  }
}
