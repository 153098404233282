@import '../../../../assets/styles/index.scss';

.wrapper {
  @include wrapper-styles('../../../../assets/img/bg8.jpg');

  align-items: end;
}

.woman {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 426.5px;
  max-height: 800px;
  align-self: flex-end;
  background-size: cover;
}

.title {
  max-width: 353.5px;
  max-height: 129.5px;
  margin-bottom: 50px;
}

@media (max-width: 470px) {
  .wrapper {
    justify-content: flex-start;
  }

  .title {
    margin-bottom: unset;
  }

  .woman {
    scale: 0.75;
    transform-origin: bottom left;
  }
}
