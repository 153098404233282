.wrapper {
  position: absolute;
  bottom: 20px;
  left: 50%;
  display: flex;
  width: 90%;
  height: 50px;
  align-items: center;
  border-radius: 50px;
  background-color: rgb(36, 25, 24, 0.5);
  transform: translateX(-50%);
}

.text {
  margin-right: 20px;
  margin-left: 50px;
  font-family: 'Loreal';
  font-size: 20.5px;
  font-weight: 200;
}

.pointsWrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-right: 30px;
}

.point {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 15px;
  background-color: #fff;

  &:last-child {
    margin-right: 0;
  }

  &.active {
    flex-grow: 1;
    border-radius: 20px;
    background: linear-gradient(#fe7b95, #ffb696);
  }
}
