@font-face {
  font-family: 'Muller';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/MullerRegular.woff2') format('woff2'),
    url('../fonts/MullerRegular.woff') format('woff');
}

@font-face {
  font-family: 'Muller';
  font-style: italic;
  font-weight: 800;
  src: url('../fonts/MullerBoldItalic.woff2') format('woff2'),
    url('../fonts/MullerBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Muller';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/MullerLight.woff2') format('woff2'),
    url('../fonts/MullerLight.woff') format('woff');
}

@font-face {
  font-family: 'Muller';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/MullerLightItalic.woff2') format('woff2'),
    url('../fonts/MullerLightItalic.woff') format('woff');
}

@font-face {
  font-family: 'Muller';
  font-weight: 500;
  src: url('../fonts/MullerMedium.woff2') format('woff2'),
    url('../fonts/MullerMedium.woff') format('woff');
}

@font-face {
  font-family: 'Muller';
  font-weight: 100;
  src: url('../fonts/MullerThin.woff2') format('woff2'),
    url('../fonts/MullerThin.woff') format('woff');
}

@font-face {
  font-family: 'Loreal';
  font-weight: 800;
  src: url('../fonts/LOREALPRO-Bold.woff2') format('woff2'),
    url('../fonts/LOREALPRO-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Loreal';
  font-weight: 200;
  src: url('../fonts/LOREALPRO-ExtraLight.woff2') format('woff2'),
    url('../fonts/LOREALPRO-ExtraLight.woff') format('woff');
}
