@import '../../../../assets/styles/index.scss';

.wrapper {
  @include wrapper-styles('../../../../assets/img/bg15.jpg');

  align-items: flex-start;
}

.lili {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 371px;
  max-height: 865px;
  background-size: cover;
}

.title {
  max-width: 294px;
  max-height: 118px;
  align-self: flex-end;
  margin-bottom: 240px;
}

@media (max-width: 470px) {
  .wrapper {
    justify-content: flex-start;
  }

  .title {
    margin-bottom: unset;
  }

  .lili {
    scale: 0.7;
    transform-origin: bottom left;
  }
}
