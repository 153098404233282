@import '../../../../assets/styles/index.scss';

.wrapper {
  @include wrapper-styles('../../../../assets/img/bg10.jpg');

  &.blocked {
    pointer-events: none;
  }
}

.checkboxWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 20px;
}

.title {
  font-size: 40px;
  font-weight: 400;
  pointer-events: none;
  text-align: left;
}

.checkbox {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 30px 110px;
  padding-left: 30px;
  border-radius: 50px;
  margin-bottom: 30px;
  animation: animation_to_fix_backdrop_filter 0.3s both;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  font-size: 30px;
  font-weight: 300;
  gap: 20px;
  text-align: left;

  &::before {
    display: block;
    width: 72px;
    height: 72px;
    border-radius: 20px;
    background: rgba(22, 19, 20, 0.7);
    content: '';
  }

  &.correct {
    position: relative;

    &::after {
      position: absolute;
      top: 32px;
      left: 31px;
      display: block;
      width: 72px;
      height: 72px;
      background: url('../../../../assets/img/correct.png');
      background-size: contain;
      content: '';
    }
  }

  &.incorrect {
    position: relative;

    &::after {
      position: absolute;
      top: 32px;
      left: 31px;
      display: block;
      width: 72px;
      height: 72px;
      background: url('../../../../assets/img/incorrect.png');
      background-size: contain;
      content: '';
    }
  }
}

@media (max-width: 470px) {
  .title,
  .checkbox {
    font-size: 16px;
  }

  .checkbox {
    padding: 30px;
    margin-bottom: 10px;

    &::before {
      width: 36px;
      height: 36px;
      border-radius: 10px;
    }

    &.correct,
    &.incorrect {
      &::after {
        width: 36px;
        height: 36px;
      }
    }
  }
}

@keyframes animation_to_fix_backdrop_filter {
  from {
    transform: translate3d(0, 0.5px, 0);
  }
}
