@import '../../../../assets/styles/index.scss';

.wrapper {
  @include wrapper-styles('../../../../assets/img/bg3.jpg');

  align-items: unset;
  justify-content: unset;
}

.man {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 540px;
  align-self: flex-end;
  background-size: cover;
}

.title {
  max-width: 447px;
  align-self: flex-end;
  margin-top: 60px;
}

@media (max-width: 470px) {
  .title {
    margin-top: unset;
    margin-left: 0;
  }

  .man {
    scale: 0.8;
    transform-origin: bottom left;
  }
}
