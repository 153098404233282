#root {
  overflow: hidden;
  background-color: rgb(13, 12, 12);
}

.App {
  display: flex;
  height: 100vh;
  height: 100dvh;
  align-items: center;
  background-color: rgb(13 12 12);
  text-align: center;
}
