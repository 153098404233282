@import '../../../assets/styles/index.scss';

.wrapper {
  @include wrapper-styles('../../../assets/img/bg2.jpg');

  flex-direction: row;
  justify-content: unset;
}

.title {
  max-width: 359px;
  margin-top: 240px;
}

.buttonWrapper {
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
}

.button {
  position: relative;
  padding: 28px 27.5px;
  font-family: 'Loreal';
  font-size: 48px;
  font-weight: 800;
}

@media (max-width: 470px) {
  .wrapper {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: center;
    background-position: center -30px;
  }

  .title {
    margin-top: 0;
  }

  .buttonWrapper {
    align-self: center;
  }
}
