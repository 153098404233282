@import '../../../../assets/styles/index.scss';

.wrapper {
  @include wrapper-styles('../../../../assets/img/bg12.jpg');

  justify-content: space-between;
}

.molekuleWrapper {
  display: flex;
  height: 70%;
  align-items: flex-start;
  padding: 20px 20px 10px;
  border-radius: 50px;
  margin: 20px;
  animation: animation_to_fix_backdrop_filter 0.3s both;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}

.container {
  display: flex;
  width: 100%;
  align-items: center;
}

.pipeWrapper {
  position: relative;
}

.pipe {
  max-width: 264.5px;
  max-height: 572px;
  margin-right: 50px;
  background-size: contain;

  &.hidden {
    visibility: hidden;
  }

  &.helping {
    filter: drop-shadow(0 0 38px #39e6f6);
  }
}

.helpContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.molekule {
  max-width: 125px;
  max-height: 160px;
  margin-bottom: 30px;
  background-size: contain;

  &.hidden {
    visibility: hidden;
  }
}

.help {
  max-width: 74.5px;
  max-height: 47px;
  background-size: contain;

  &.hidden {
    display: none;
  }
}

.messageWrapper {
  min-width: 495px;
  flex-direction: row-reverse;
  align-items: flex-start;
  align-self: unset;
  padding: 30px 0 20px;
}

.textMolekule {
  position: absolute;
  left: 60px;
  align-self: flex-end;
  padding: 0 60px 0 40px;
  font-size: 24px;
  font-weight: 300;
}

.img {
  width: 42px;
  height: 63px;
  margin-left: 55px;
  background: url('../../../../assets/img/help_hand.png') no-repeat;
  background-size: contain;
}

.text {
  padding: 0 60px 30px 40px;
}

.video {
  display: none;

  &.active {
    display: block;
    max-width: 264.5px;
    max-height: 572px;
    border-radius: 50px;
    margin-right: 50px;
    background-size: contain;
  }
}

@media (max-width: 470px) {
  .messageWrapper {
    width: calc(100% - 10px);
    min-width: unset;
    padding: 20px 40px;
    margin: 0 5px;
    gap: 30px;
  }

  .molekuleWrapper {
    width: calc(100% - 20px);
    height: auto;
    flex-direction: column;
    padding: 20px;
    margin: auto 0 10vh;
    gap: 10px;
  }

  .container {
    justify-content: space-between;
  }

  .pipe {
    width: 150px;
    margin-right: unset;
  }

  .molekule {
    width: 80px;
  }

  .helpContainer {
    margin-right: 10px;
    margin-bottom: 70px;
  }

  .help {
    width: 50px;
  }

  .video {
    &.active {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 24px;
    }
  }

  .img {
    width: 30px;
    height: 40px;
    margin: 0;
  }

  .text {
    padding: 0;
  }

  .textMolekule {
    position: static;
    padding: 0;
    margin: 0 auto;
    font-size: 16px;
  }
}

@keyframes animation_to_fix_backdrop_filter {
  from {
    transform: translate3d(0, 0.5px, 0);
  }
}
